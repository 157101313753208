<div class="d-flex justify-content-center">
  <div class="sundo-container" *ngIf="smot$ | async">
    <div class="top-text">{{'translate.operations.smot-image-upload.uploadSmotImage' | translate}}</div>
    <div class="d-flex justify-content-center">
      <input
        type="file"
        style="display: none"
        accept="image/*"
        (change)="onFileSelect($event)"
        #fileInput>
      <app-warning-button (click)="fileInput.click()">{{'translate.operations.shared.uploadImage' | translate}}</app-warning-button>
    </div>
  </div>
</div>
