<ng-container *ngIf="!displayImages else images">
  <div class="sundo-container medium h-100 m-auto position-relative">
    <div class="back-button" (click)="previousStep()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
    <app-h2 class="text-center mb-5">{{'translate.operations.smot-scanner.scanAnyQRCode' | translate}}</app-h2>
    <div class="d-flex justify-content-center">
      <app-js-qr-scanner [badScan]="badScan" (codeScanned)="handleSuccessfulCodeScan($event)"></app-js-qr-scanner>
    </div>
    <p class="error-message-minimal text-center" *ngIf="incorrectSmotScanned">
      {{'translate.routes.scannedIncorrectSmot' | translate}}
    </p>
    <div class="buttons">
      <app-warning-button [fullWidth]="true" (click)="this.displayImages = true">{{'Toon afbeeldingen' | translate}}</app-warning-button>
    </div>
  </div>
</ng-container>

<ng-template #images>
  <app-route-media [data]="data" (returnToStep)="displayImages = false"></app-route-media>
</ng-template>
