import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {OperationService} from '../../../services/operation.service';
import {SmotService} from '../../../services/smot.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceService} from '../../../services/device.service';
import {FileUploadService} from '../../../services/file-upload.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {ActionDTO} from '../../../dtos/OperationDTOs/actionDTO';

@Component({
  selector: 'app-smot-repair',
  templateUrl: './smot-repair.component.html',
  styleUrls: ['./smot-repair.component.scss', '../operations.scss', '../smot-operation/smot-operation.component.scss']
})
export class SmotRepairComponent implements OnInit, OnDestroy {

  routeSubscription: Subscription;
  smot$: Observable<SmotDTO>;
  smotId: string;
  bckId: string = null;
  casId: string = null;
  badScan = false;

  showScanner = false;
  loading: boolean = false;

  @ViewChild('stepper') progressStepper;

  partControl = new FormControl(null, Validators.required);
  file = null;

  parts: any[] = [
    {
      value: 'Backplate',
      label: this.translate.instant('translate.operations.smot-repair.parts.backplate'),
    },
    {
      value: 'Casing',
      label: this.translate.instant('translate.operations.smot-repair.parts.casing'),
    },
    {
      value: 'Motor',
      label: this.translate.instant('translate.operations.smot-repair.parts.motor'),
    },
    {
      value: 'Sensor',
      label: this.translate.instant('translate.operations.smot-repair.parts.sensor'),
    },
    {
      value: 'Antenne',
      label: this.translate.instant('translate.operations.smot-repair.parts.antenna'),
    },
    {
      value: 'Batterij',
      label: this.translate.instant('translate.operations.smot-repair.parts.battery'),
    },
    {
      value: 'Scherm',
      label: this.translate.instant('translate.operations.smot-repair.parts.screen'),
    },
    {
      value: 'Nozzle',
      label: this.translate.instant('translate.operations.smot-repair.parts.nozzle'),
    },
    {
      value: 'Andere',
      label: this.translate.instant('translate.operations.smot-repair.parts.other'),
    },
  ];

  constructor(private operationService: OperationService,
              private smotService: SmotService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private scanService: ScannerService,
              private deviceService: DeviceService,
              private fileUploadService: FileUploadService,
              private toastr: ToastrNotificationService,
              private router: Router) {
    this.routeSubscription = this.activatedRoute.paramMap.subscribe(params => this.smotId = params.get('id'));
  }

  ngOnInit(): void {
    this.smot$ = this.operationService.operationSmot$.pipe(
      switchMap(smot => {
        if (smot !== undefined) { return of(smot); }
        return this.smotService.getSmot(+this.smotId);
      }),
    );
  }

  handleNextOnPartSelect() {
    if (this.partControl.value === undefined || this.partControl.value === null) {
      return;
    }
    if (this.partControl.value !== 'Backplate' && this.partControl.value !== 'Casing') {
      this.progressStepper.continue();
      return;
    }
    this.showScanner = true;
  }

  onFileSelect(event) {
    this.file = event.target.files[0];
    this.loading = true;
    this.fileUploadService.uploadOperationsPicture(this.file, +this.smotId, 'repair').pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.progressStepper.continue();
        this.loading = false;
      }),
    ).subscribe();
  }

  scanCompleteHandler(event, type: 'backplate' | 'casing') {
    this.badScan = false;
    if (event === undefined) {
      this.badScan = true;
      return;
    }

    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) {
      this.badScan = true;
      return;
    }

    if (scannedQR.type.toUpperCase() !== type.toUpperCase()) {
      this.badScan = true;
      return;
    }

    this.loading = true;

    if (scannedQR.type === 'backplate') {
      this.handleBackplateScan(scannedQR);
    }

    if (scannedQR.type === 'casing') {
      this.handleCasingScan(scannedQR);
    }
  }

  repairCompleteHandler(smot: SmotDTO) {
    this.loading = true;
    const operation: OperationDTO = this.createRepairOperation(smot);
    this.saveOperation(operation);
  }

  private handleBackplateScan(scannedQR: ScannedQR): void {
    this.deviceService.getDeviceByBackplate(scannedQR.backplateId).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          this.bckId = scannedQR.backplateId;
          this.progressStepper.continue();
        } else {
          this.toastr.showError('Backplate is already in use, please deinstall first', 'Action required');
        }
        this.loading = false;
      }),
    ).subscribe();
  }

  private handleCasingScan(scannedQR: ScannedQR): void {
    this.deviceService.getDeviceByCasing(scannedQR.casingId).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          this.casId = scannedQR.casingId;
          this.progressStepper.continue();
        } else {
          this.toastr.showError('Casing is already in use, please deinstall first', 'Action required');
        }
        this.loading = false;
      }),
    ).subscribe();
  }

  private createRepairOperation(smot): OperationDTO {
    return {
      operationType: 'REPAIR',
      smot: smot,
      info: this.createInfoString(smot)
    } as OperationDTO;
  }

  private createInfoString(smot: SmotDTO): String {
    const info: any = {
      repairPart: this.partControl.value
    };

    if (this.partControl.value === 'Backplate') {
      info.newBckId = +this.bckId;
      info.oldBckId = smot.device?.backplate?.backplateId;
    }

    if (this.partControl.value === 'Casing') {
      info.newCasId = +this.casId;
      info.oldCasId = smot.device?.casingId;
    }

    return JSON.stringify(info);
  }

  private saveOperation(operation: OperationDTO): void {
    // Save the operation
    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.operationService.removeOperationSmot();
        this.toastr.showSucces(this.translate.instant('translate.operations.smot-repair.succesfullyRepairedPart',
            {item: this.partControl.value}),
          this.translate.instant('translate.general.succes'));
        this.router.navigate(['operations']);
        this.loading = false;
      }),
    ).subscribe();


    // Override the event counter if a backplate has been scanned
    if (+this.bckId) {
      this.overrideEventCounterForBackplate(+this.bckId, operation.smot.lastEventCounter);
    }
  }

  private overrideEventCounterForBackplate(backplateId: number, events: number) {
    this.deviceService.getBackplateById(backplateId).pipe(
      filter(x => x !== undefined),
      switchMap(backplate => {
        const params: ActionDTO = {
          iccid: backplate.iccid,
          overrideId: '21',
          lastEventCounter: events,
        };
        return this.smotService.performSmotAction(params);
      })
    ).subscribe();
  }

  /**
   * Unsubscribes from all infinite observables
   */
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}

