import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import jsQR from 'jsqr';

@Component({
  selector: 'app-js-qr-scanner',
  templateUrl: './js-qr-scanner.component.html',
  styleUrls: ['./js-qr-scanner.component.scss']
})
export class JsQrScannerComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement') videoElement: ElementRef;
  @Input() badScan: boolean = false;
  @Input() processingScan: boolean = false;
  @Output() codeScanned: EventEmitter<string> = new EventEmitter();

  showQRScanner: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.startVideoStream();
  }

  ngAfterViewInit(): void {
    this.startScanning();
  }

  emitScan(scannedData: string) {
    if (!this.processingScan) {
      this.codeScanned.emit(scannedData.trim());
    }
  }

  private startVideoStream() {
    navigator.mediaDevices.getUserMedia({
      video:
        {
          facingMode: 'environment',
          aspectRatio: 1,
          height: 300,
          width: 300
        }
    }).then(stream => {
      this.videoElement.nativeElement.srcObject = stream;
      this.videoElement.nativeElement.play();
    });
  }

  private startScanning() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    this.videoElement.nativeElement.addEventListener('play', () => {
      setInterval(() => {
        if (this.videoElement.nativeElement.readyState === this.videoElement.nativeElement.HAVE_ENOUGH_DATA) {
          canvas.height = this.videoElement.nativeElement.videoHeight;
          canvas.width = this.videoElement.nativeElement.videoWidth;
          context.drawImage(this.videoElement.nativeElement, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            this.emitScan(code.data);
          }
        }
      }, 200); // Execute every 500 milliseconds
    });
  }

}
