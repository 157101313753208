<div class="sundo-container medium h-100 m-auto position-relative">
  <div class="back-button" (click)="previousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="'QUESTION'">
        <app-h2 class="text-center">{{'translate.operations.smot-maintenance.doesTheSmotNeedRefilling' | translate}}</app-h2>
        <div class="buttons d-flex justify-content-between">
          <app-success-button [size]="'small'" (click)="step = 'SCANNER'">{{'translate.form.yes' | translate}}</app-success-button>
          <app-danger-button [size]="'small'" (click)="nextStep()">{{'translate.form.no' | translate}}</app-danger-button>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'SCANNER'">
        <app-h2 class="text-center mb-5">{{'translate.operations.shared.scanQRCodeOnBag' | translate}}</app-h2>
        <div class="d-flex justify-content-center">
          <app-js-qr-scanner [badScan]="badScan" [loading]="loading" (codeScanned)="handleSuccessfulCodeScan($event)"></app-js-qr-scanner>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'FINISH'">
      <app-h2 class="text-center">{{'translate.operations.shared.bagSuccesfullyReplaced' | translate}}</app-h2>
      <div class="buttons">
        <app-success-button [fullWidth]="true">{{'translate.form.next' | translate}}</app-success-button>
      </div>
    </ng-container>
  </ng-container>
</div>



