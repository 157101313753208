import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationsRoutingModule} from './operations-routing.module';
import {SmotScannerComponent} from './smot-scanner/smot-scanner.component';
import {TranslateModule} from '@ngx-translate/core';
import {SmotOperationComponent} from './smot-operation/smot-operation.component';
import {SmotMaintenanceComponent} from './smot-maintenance/smot-maintenance.component';
import {SmotRepairComponent} from './smot-repair/smot-repair.component';
import {SmotImageUploadComponent} from './smot-image-upload/smot-image-upload.component';
import {PluginsModule} from '../plugins/plugins.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CreateDeviceComponent} from './create-device/create-device.component';
import {CreateSmotComponent} from './create-smot/create-smot.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {StartRouteComponent} from './routes/start-route/start-route.component';
import {RouteOverviewComponent} from './routes/route-overview/route-overview.component';
import {RouteCommentsComponent} from './routes/route-comments/route-comments.component';
import {UploadImageComponent} from './routes/upload-image/upload-image.component';
import {RefillComponent} from './routes/refill/refill.component';
import {FinishRouteComponent} from './routes/finish-route/finish-route.component';
import {RouteMediaComponent} from './routes/route-media/route-media.component';
import {ActiveRouteStepComponent} from './routes/active-route-step.component';
import {DirectivesModule} from '../../directives/directives.module';
import {ScannerModule} from '../../components/scanner/scanner.module';
import {MatIconModule} from '@angular/material/icon';
import {ScanSmotComponent} from './routes/scan-smot/scan-smot.component';
import {SundoHeadersModule} from '../../components/headers/sundo-headers.module';
import {SundoButtonsModule} from '../../components/buttons/sundo-buttons.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
    declarations: [
        SmotScannerComponent,
        SmotOperationComponent,
        SmotMaintenanceComponent,
        SmotRepairComponent,
        SmotImageUploadComponent,
        CreateDeviceComponent,
        CreateSmotComponent,
        StartRouteComponent,
        RouteOverviewComponent,
        RouteCommentsComponent,
        UploadImageComponent,
        RefillComponent,
        FinishRouteComponent,
        RouteMediaComponent,
        ActiveRouteStepComponent,
        ScanSmotComponent,
    ],
    imports: [
        CommonModule,
        OperationsRoutingModule,
        TranslateModule,
        PluginsModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatExpansionModule,
        NgxMaterialTimepickerModule,
        MatCheckboxModule,
        MatListModule,
        FormsModule,
        FormFieldsModule,
        DirectivesModule,
        ScannerModule,
        MatIconModule,
        SundoHeadersModule,
        SundoButtonsModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
    ]
})
export class OperationsModule { }
