import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../forms/validation-forms/validation-forms.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss']
})
export class InputModalComponent implements OnInit {
  question: String;
  type: 'number' | 'email' | 'text' | 'password' = 'text';
  control: FormControl = new FormControl('', [Validators.pattern(this.vf.formRules.nonEmpty), Validators.required]);
  multiline: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogref: MatDialogRef<InputModalComponent>,
    private vf: ValidationFormsService,
    private translate: TranslateService) {
    this.question = this.translate.instant(data.key);
    this.multiline = !!data.multiline;
    if (data.type) {
      this.type = data.type;
    }
    this.control.setValue(data.value);
  }

  ngOnInit(): void {
  }

  public closeDialog() {
    this.dialogref.close(this.control.value);
  }

}
