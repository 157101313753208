<div class="d-flex justify-content-center">
  <div *ngIf="smot$ | async as smot" class="sundo-container medium">
    <div class="stepper">
      <app-progress-step #stepper [amountOfSteps]="5" [currentStep]="1"></app-progress-step>
    </div>
    <div class="d-flex justify-content-center">

    <div #step1 *ngIf="stepper.currentStep === 1">
        <div class="top-text mb-4">{{'translate.operations.smot-maintenance.checkTheFollowingComments' | translate}}</div>
            <p class="text-center" *ngIf="comments.length <= 0">{{'translate.operations.smot-maintenance.noCommentsForThisSMOTSpot' | translate}}</p>
        <div class="container">
          <div class="row">
            <div class="col-8">Opmerking</div>
            <div class="col-4">Opgelost?</div>
          </div>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-2">Ja</div>
            <div class="col-2">Nee</div>
          </div>
          <ng-container  *ngFor="let comment of comments">
            <div class="row my-2">
              <div class="col-8">{{comment.comment.content}}</div>
              <div class="col-2" *ngIf="comment.type === CommentType.COMMENT">
                <mat-checkbox [(ngModel)]="comment.solved" (change)="comment.solved = true"></mat-checkbox>
              </div>
              <div class="col-2" *ngIf="comment.type === CommentType.COMMENT">
                <mat-checkbox  *ngIf="comment.solved === false || comment.solved !== null" [(ngModel)]="!comment.solved" (change)="handleUncheck($event, comment)"></mat-checkbox>
                <mat-checkbox *ngIf="comment.solved === null" (change)="comment.solved = false"></mat-checkbox>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="text-center mt-3">
          <span class="error-message" *ngIf="commentsSubmitted && checkIfAnyCommentsUnchecked()">{{'translate.operations.smot-maintenance.checkAllCommentsBeforeContinuing' | translate}}</span>
        </div>
        <app-success-button [fullWidth]="true" (click)="submitCommentsForm()">{{'translate.form.submit' | translate}}</app-success-button>
      </div>

    <div #step2 *ngIf="stepper.currentStep === 2">
        <div class="top-text">{{'translate.operations.smot-maintenance.imageBeforeCleaning' | translate}}</div>
        <input
          type="file"
          style="display: none"
          accept="image/*"
          (change)="onFileSelect($event, 'before', smot);"
          #beforeFileInput>
          <div>
            <app-warning-button [size]="'big'" [loading]="disableButtons" (click)="beforeFileInput.click()">{{'translate.operations.shared.uploadImage' | translate}}</app-warning-button>
          </div>
            <app-warning-button [size]="'big'" (click)="nextStep()">{{'translate.form.skip' | translate}}</app-warning-button>
      </div>
    <div #step3 *ngIf="stepper.currentStep === 3">
        <div class="top-text">{{'translate.operations.smot-maintenance.imageAfterCleaning' | translate}}</div>
        <input
          type="file"
          style="display: none"
          accept="image/*"
          (change)="onFileSelect($event, 'after', smot);"
          #afterFileInput>
        <div>
          <app-warning-button [size]="'big'"  [loading]="disableButtons" (click)="afterFileInput.click()">{{'translate.operations.shared.uploadImage' | translate}}</app-warning-button>
        </div>
        <app-warning-button [size]="'big'"  (click)="nextStep()">{{'translate.form.skip' | translate}}</app-warning-button>
      </div>
    <div #step4 *ngIf="stepper.currentStep === 4">
        <ng-container *ngIf="!refillSmot else sunscreenScanner">
          <div class="top-text">{{'translate.operations.smot-maintenance.doesTheSmotNeedRefilling' | translate}}</div>
          <div class="d-flex justify-content-around w-100">
            <app-success-button (click)="displayRefill()">{{'translate.form.yes' | translate}}</app-success-button>
            <app-danger-button (click)="nextStep()">{{'translate.form.no' | translate}}</app-danger-button>
          </div>
        </ng-container>

        <ng-template #sunscreenScanner>
          <app-h3 class="text-center">{{'translate.operations.shared.scanQRCodeOnBag' | translate}}</app-h3>
          <div class="d-flex justify-content-center">
            <app-js-qr-scanner [processingScan]="processingScan" [badScan]="badScan" (codeScanned)="handleSunscreenScanComplete($event, smot)"></app-js-qr-scanner>
          </div>
        </ng-template>
      </div>
    </div>
    <div #step5 *ngIf="stepper.currentStep === 5" class="text-center">
      <div class="top-text">{{'translate.operations.smot-maintenance.maintenanceSuccessful' | translate}}</div>
      <div class="top-text">{{'translate.operations.smot-maintenance.testTheSmot' | translate}}</div>
      <app-warning-button [size]="'big'" [loading]="disableButtons" (click)="displayAddCommentModal(smot)">{{'translate.routes.addComment' | translate}}</app-warning-button>
      <app-success-button [size]="'big'" [loading]="disableButtons" (click)="saveCleanOperation(smot)">{{'translate.form.submit' | translate}}</app-success-button>
    </div>
  </div>
</div>
