import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {DeviceDTO} from '../dtos/deviceDTO';
import {environment} from '../../environments/environment';
import {BackplateDTO} from '../dtos/backplateDTO';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private deviceUrl: string = environment.apiRoot + '/devices';

  private operationDevice: DeviceDTO;

  constructor(private helper: ApiHelperService) {
  }

  getDeviceById(id: number): Observable<DeviceDTO> {
    const url = this.deviceUrl + '/' + id;
    return this.helper.getRequest<DeviceDTO>(url);
  }
  /**
   * Gets a device with the given backplate_id
   */
  getDeviceByBackplate(backplateId: string): Observable<DeviceDTO> {
    return this.helper.getRequest<DeviceDTO>(this.deviceUrl + '/bck/' + backplateId);
  }

  createDevice(device: DeviceDTO): Observable<DeviceDTO> {
    const createUrl = this.deviceUrl + '/create';
    return this.helper.postRequest<DeviceDTO>(createUrl, device);
  }

  getDeviceByCasing(casingId: string): Observable<DeviceDTO> {
    return this.helper.getRequest<DeviceDTO>(this.deviceUrl + '/cas/' + casingId);
  }

  disassembleDevice(deviceId: string): Observable<DeviceDTO> {
    return this.helper.putRequest(this.deviceUrl + `/${deviceId}/disassemble`, null);
  }

  saveDevice(device: DeviceDTO): Observable<DeviceDTO> {
    // return this.helper.putRequest(this.deviceUrl, device);
    return null; // Not implemented in backend
  }

  getBackplateById(bckId: number): Observable<BackplateDTO> {
    return this.helper.getRequest<BackplateDTO>(this.deviceUrl + '/backplate/' + bckId);
  }

  setOperationDevice(device: DeviceDTO) {
    this.operationDevice = device;
  }

  getOperationDevice() {
    return this.operationDevice;
  }

  instanceOfDevice(obj: any) {
    return 'backplate' in obj && 'backplateId' in obj.backplate && 'casingId' in obj;
  }
}
