import 'rxjs/add/operator/map';
import {Role} from './models/role';

export interface NavData {
  key?: string;
  name?: string;
  url?: string;
  icon?: string;
  roles?: Array<Role>;
}


export const sideNavItems: NavData[] = [
  {
    name: 'Dashboard',
    key: 'translate.nav.dashboard' ,
    url: '/dashboard',
    icon: 'icon-home',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.FACILITATORADMIN, Role.PRODUCER, Role.PRODUCERADMIN, Role.VIEWER, Role.STAFF]
  },
  {
    name: 'SMOTSpots',
    key: 'translate.smot.smotSpots',
    url: '/smotspots',
    icon: 'cil-sun',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.FACILITATORADMIN, Role.PRODUCER, Role.PRODUCERADMIN, Role.VIEWER, Role.STAFF]
  },
  {
    name: 'Map',
    key: 'translate.nav.map',
    url: '/smotMap',
    icon: 'icon-map',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.FACILITATORADMIN, Role.PRODUCER, Role.PRODUCERADMIN, Role.VIEWER, Role.STAFF]
  },
  {
    name: 'Performance',
    key: 'translate.nav.performance',
    url: '/performance',
    icon: 'cil-bar-chart',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.FACILITATORADMIN, Role.PRODUCER, Role.PRODUCERADMIN, Role.VIEWER]
  },
  {
    name: 'Operations',
    key: 'translate.nav.operations',
    url: '/operations',
    icon: 'cil-qr-code',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.FACILITATORADMIN, Role.PRODUCERADMIN, Role.STAFF]
  },
  {
    name: 'Routes',
    key: 'translate.nav.routes',
    url: '/route',
    icon: 'cil-compass',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER]
  },
  {
    name: 'Issues',
    key: 'translate.nav.issues',
    url: '/issue-overview',
    icon: 'cil-bug',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER]
  },
  {
    name: 'Admin',
    key: 'translate.nav.admin',
    url: '/admin',
    icon: 'cil-terminal',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER]
  },
  {
    name: 'Overrides',
    key: 'translate.nav.overrides',
    url: '/overrides',
    icon: 'cil-paper-plane',
    roles: [Role.SYSTEM, Role.ADMIN]
  },
  {
    name: 'Instructions',
    key: 'translate.nav.instructions',
    url: '/instructions',
    icon: 'cil-video',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER, Role.PRODUCER, Role.FACILITATORADMIN, Role.FACILITATOR, Role.STAFF, Role.VIEWER]
  }
];
export const topNavItems: NavData[] = [
  {
    name: 'Users',
    key: 'translate.nav.users',
    url: '/users',
    roles: [Role.SYSTEM, Role.FACILITATORADMIN, Role.ADMIN, Role.PRODUCER, Role.PRODUCERADMIN, Role.PARTNER]
  },
  {
    name: 'Facilitators',
    key: 'translate.nav.facilitators',
    url: '/facilitators',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER]
  },
  {
    name: 'Producers',
    key: 'translate.nav.producers',
    url: '/producers',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER] },
  {
    name: 'Sunscreen',
    key: 'translate.nav.sunscreen',
    url: '/sunscreen',
    roles: [Role.SYSTEM, Role.ADMIN, Role.PARTNER]  }
];

