<div class="d-flex justify-content-center">
  <div *ngIf="device$ | async as device" class="sundo-container medium">
    <div class="stepper">
      <app-progress-step [amountOfSteps]="3" [currentStep]="1" #stepper></app-progress-step>
    </div>

    <ng-container #step1 *ngIf="stepper.currentStep === 1">
      <ng-container *ngIf="!showScanner else scanner">
        <div class="top-text">{{ 'translate.operations.create-device.deviceNotCreated' | translate}}</div>
        <app-success-button [fullWidth]="true" (click)="showScanner = true">{{ 'translate.operations.create-device.createDevice' | translate}}</app-success-button>
      </ng-container>
      <ng-template #scanner>
        <ng-container *ngIf="device.casingId === 0 else backplate">
          <div class="top-text">{{ 'translate.operations.shared.scanQRInsideCasing' | translate}}</div>
        </ng-container>
        <ng-template #backplate>
          <div class="top-text">{{ 'translate.operations.shared.scanQRonBackplate' | translate}}</div>
        </ng-template>
        <div class="d-flex justify-content-center">
          <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanCompleteHandler($event, device)"></app-js-qr-scanner>
        </div>
      </ng-template>
    </ng-container>

    <ng-container #step2 *ngIf="stepper.currentStep === 2">
      <div class="top-text d-flex justify-content-around">
        <div>
          {{ 'translate.operations.shared.backplateId' | translate}}:
          <div>
            {{device.backplate.backplateId}}
          </div>
        </div>
        <div>
          {{ 'translate.operations.shared.casingId' | translate}}
          <div>
            {{device.casingId}}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="buttons">
          <app-warning-button [fullWidth]="true" (click)="changeBackplate(device)">{{ 'translate.operations.create-device.otherBackplate' | translate}}</app-warning-button>
          <app-warning-button [fullWidth]="true" (click)="changeCasing(device)">{{ 'translate.operations.create-device.otherCasing' | translate}}</app-warning-button>
          <app-success-button [fullWidth]="true" (click)="createDevice(device)">{{ 'translate.operations.create-device.createDevice' | translate}}</app-success-button>
        </div>
      </div>
    </ng-container>

    <ng-container #step3 *ngIf="stepper.currentStep === 3">
      <div class="top-text"> {{ 'translate.operations.create-device.successLinkDevice' | translate}}</div>
      <div class="d-flex justify-content-center">
        <div class="buttons">
          <app-success-button [fullWidth]="true" (click)="goToCreateSmot()">{{ 'translate.operations.create-device.linkDevice' | translate}}</app-success-button>
          <app-warning-button [fullWidth]="true" (click)="goToOperations()">{{ 'translate.form.skip' | translate}}</app-warning-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
