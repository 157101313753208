import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ROUTE_CUSTOM_FLOW,
  ROUTE_DEINSTALL_FLOW,
  ROUTE_INSTALL_FLOW,
  ROUTE_MAINTENANCE_FLOW,
  RouteFlow
} from '../../../models/routeFlows';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, tap} from 'rxjs/operators';
import {RouteService} from '../../../services/route.service';
import {RouteStepDTO} from '../../../dtos/RouteDTOs/routeDTO';
import {
  RenderDynamicComponentDirective
} from '../../../directives/render-dynamic-components/render-dynamic-component.directive';
import moment from 'moment/moment';
import {PlannedRouteDTO} from '../../../dtos/RouteDTOs/plannedRouteDTO';
import {EMPTY, Subscription} from 'rxjs';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';

@Component({
  selector: 'app-active-route-step',
  templateUrl: './active-route-step.component.html',
  styleUrls: ['./active-route-step.component.scss']
})
export class ActiveRouteStepComponent implements OnInit, OnDestroy {
  @ViewChild(RenderDynamicComponentDirective, {static: true}) renderStep!: RenderDynamicComponentDirective;

  stepId: number;
  plannedRouteId: number;
  plannedRoute: PlannedRouteDTO;
  onNextSubscription: Subscription;
  onPreviousSubscription: Subscription;

  flow: RouteFlow;

  constructor(private activatedRoute: ActivatedRoute,
              private routeService: RouteService,
              private toastr: ToastrNotificationService,
              private router: Router) {
    this.activatedRoute.params.pipe(
      tap(params => {
        this.plannedRouteId = params['plannedRouteId'];
        this.stepId = params['stepId'];
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.subscribeToOnStepCompleteChanges();
    this.subscribeToOnStepReturnChanges();
    this.fetchRoute();
  }

  private fetchRoute() {
    this.routeService.getPlannedRouteById<PlannedRouteDTO>(this.plannedRouteId).pipe(
      filter(x => x !== undefined),
      tap(route => this.plannedRoute = route),
      tap(() => this.loadComponent()),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }

  private loadComponent() {
    const routeStep = this.plannedRoute.route.steps.find(s => +s.routeStepId === +this.stepId);
    this.getFlowBasedOnStep(routeStep);
    const step: any = this.flow.steps[this.getCurrentStepIndex()];
    step.data.stepId = this.stepId;
    step.data.plannedRoute = this.plannedRoute;

    if (step.data.headerString === 'custom') {
      step.data.headerString = routeStep.content;
    }

    step.data.uploadUrl = step.data.uploadUrl + routeStep.smot.logicalId + (step.data.suffix ? '?suffix=' + step.data.suffix : '');

    const viewContainerRef: any = this.renderStep.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(step.component);
    componentRef.instance.data = step.data;
    return componentRef;
  }

  private subscribeToOnStepCompleteChanges() {
    this.onNextSubscription = this.routeService.onStepCompleted$.pipe(
      tap(() => this.nextStep())
    ).subscribe();
  }

  private subscribeToOnStepReturnChanges() {
    this.onPreviousSubscription = this.routeService.onStepReturn$.pipe(
      tap(() => this.previousStep())
    ).subscribe();
  }

  private nextStep() {
    let index = +this.routeService.getStepStage();
    index++;
    this.routeService.setStepStage(index.toString());
    this.loadComponent();
  }

  private previousStep() {
    if (this.getCurrentStepIndex() === 0) {
      this.router.navigate(['/operations/route-overview', this.plannedRouteId]);
      return;
    }

    let index = +this.routeService.getStepStage();
    index--;
    this.routeService.setStepStage(index.toString());
    this.loadComponent();
  }

  private getFlowBasedOnStep(routeStep: RouteStepDTO) {
    if (routeStep.maintenance) {
      this.flow = ROUTE_MAINTENANCE_FLOW;
    } else if (routeStep.installation) {
      this.flow = ROUTE_INSTALL_FLOW;
    } else if (routeStep.deinstallation) {
      this.flow = ROUTE_DEINSTALL_FLOW;
    } else {
      this.flow = ROUTE_CUSTOM_FLOW;
    }
  }

  private getCurrentStepIndex() {
    const stepStage = +this.routeService.getStepStage();
    if (!!stepStage) {
     return stepStage;
    } else {
      this.routeService.setStepStage('0');
      return 0;
    }
  }

  ngOnDestroy(): void {
    this.onNextSubscription.unsubscribe();
    this.onPreviousSubscription.unsubscribe();
  }
}
