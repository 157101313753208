<div class="d-flex justify-content-center">
  <div class="sundo-container medium">
    <div *ngIf="!showVideos" id="scanner">
      <div class="d-flex justify-content-center mb-3" *ngIf="displayRoutesButton">
        <app-primary-button [routerLink]="['start-route']">Mijn routes</app-primary-button>
      </div>
      <app-h3 class="text-center">{{'translate.operations.smot-scanner.scanAnyQRCode' | translate}}</app-h3>
      <div class="d-flex justify-content-center">
        <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanCompleteHandler($event)"></app-js-qr-scanner>
      </div>
    </div>
  </div>
</div>
