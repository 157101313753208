import {Component, Input, OnInit} from '@angular/core';
import {ScannedQR} from '../../../../models/ScannedQR';
import {tap} from 'rxjs/operators';
import {ScannerService} from '../../../../services/scanner.service';
import {OperationService} from '../../../../services/operation.service';
import {SmotService} from '../../../../services/smot.service';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {RouteService} from '../../../../services/route.service';

@Component({
  selector: 'app-scan-smot',
  templateUrl: './scan-smot.component.html',
  styleUrls: ['./scan-smot.component.scss', '../../operations.scss']
})
export class ScanSmotComponent implements OnInit, BaseRouteStepComponent {
  @Input() data: RouteFlowStepData;
  badScan = false;
  incorrectSmotScanned = false;
  displayImages: boolean = false;
  loading: boolean = false;

  constructor(private scanService: ScannerService,
              private operationService: OperationService,
              private smotService: SmotService,
              private routeService: RouteService) { }

  ngOnInit(): void {
  }

  public handleSuccessfulCodeScan(event) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.badScan = false;
    this.incorrectSmotScanned = false;

    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) {
      this.badScan = true;
      this.loading = false;
      return;
    }

    this.operationService.operationEntityScanned(+scannedQR.casingId, +scannedQR.backplateId, +scannedQR.seasonId).pipe(
      tap(smot => {
        if (smot == null) {
          this.badScan = true;
          this.loading = false;
          return;
        }

        if (this.smotService.instanceOfSmot(smot)) {
          const smotLogicalId = this.data?.plannedRoute.route.steps.find(s => +s.routeStepId === +this.data.stepId)?.smot?.logicalId;
          if (smotLogicalId === smot.logicalId) {
            this.nextStep();
          } else {
            this.incorrectSmotScanned = true;
            this.loading = false;
          }
        } else {
          this.incorrectSmotScanned = true;
          this.loading = false;
        }
      })
    ).subscribe();
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  private nextStep() {
    this.routeService.emitOnStepCompleted();
  }
}
