import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {OperationService} from '../../../services/operation.service';
import {SmotService} from '../../../services/smot.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {FileUploadService} from '../../../services/file-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {RouteService} from '../../../services/route.service';
import {CommentType} from '../../../models/commentType';
import {ResolutionStatus} from '../../../models/resolutionStatus';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {RouteStepCommentDTO} from '../../../dtos/RouteDTOs/routeDTO';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import {CreateRouteStepCommentDTO} from '../../../dtos/RouteDTOs/createRouteDTO';
import {ProgressStepComponent} from '../../plugins/progress-step/progress-step.component';

@Component({
  selector: 'app-smot-maintenance',
  templateUrl: './smot-maintenance.component.html',
  styleUrls: ['./smot-maintenance.component.scss', '../operations.scss']
})
export class SmotMaintenanceComponent implements OnInit {
  protected readonly CommentType = CommentType;
  smotId: string;
  smot$: Observable<SmotDTO>;

  loading: boolean = false;
  processingScan: boolean = false;

  comments: CommentWithCheckStatus[] = [];
  commentsSubmitted: boolean = false;

  disableButtons: boolean = false;
  refillSmot: boolean = false;
  badScan = false;

  @ViewChild('stepper') progressStepper: ProgressStepComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private operationService: OperationService,
              private routeService: RouteService,
              private smotService: SmotService,
              private scanService: ScannerService,
              private dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private fileUpload: FileUploadService,
              private router: Router,
              private changeDetector: ChangeDetectorRef) {
    this.activatedRoute.paramMap.subscribe(params => this.smotId =  params.get('id'));
  }

  private static createRefillOperation(sunscreenId: number, smot: SmotDTO) {
    return {
      smot: smot,
      operationType: 'REFILL',
      info: JSON.stringify({
        newPackageId: sunscreenId,
        previousPackageId: smot.packaging?.packageId
      })
    } as OperationDTO;
  }

  ngOnInit(): void {
    this.smot$ = this.operationService.operationSmot$.pipe(
      switchMap(smot => {
        if (smot !== undefined) { return of(smot); }
        return this.smotService.getSmot(+this.smotId);
      }),
      filter(x => x !== undefined),
      tap(smot => this.mapSmotCommentsToCommentWithCheckStatus(smot.comments))
    );
  }


  // TODO: FIX: Currently you can enter invalid Packages, but it still returns with a success status
  handleSunscreenScanComplete(event, smot) {
    if (!event || this.processingScan) { return; }
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) {
      this.badScan = true;
      this.changeDetector.detectChanges();
      return;
    }
    this.processingScan = true;


    this.postOperation(SmotMaintenanceComponent.createRefillOperation(+scannedQR.sunscreenId, smot)).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.nextStep();
        this.processingScan = false;
      }),
      catchError((err) => {
        this.processingScan = false;
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      }),
    ).subscribe();
  }

  onFileSelect(event, type: 'before' | 'after', smot: SmotDTO) {
    const _this = this;
    const file = event.target.files[0];
    const reader = new FileReader();
    this.disableButtons = true;

    this.changeDetector.detectChanges();

    reader.addEventListener('loadend', function () {
      // convert image to base64 encoded string
      const img = new Image();

      img.src = reader.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imgDimensions = _this.calculateAspectRatioFit(img.width, img.height, 1080, 1080);

        canvas.width = imgDimensions.width;
        canvas.height = imgDimensions.height;

        ctx.drawImage(img, 0, 0, imgDimensions.width, imgDimensions.height);

        // Compress the image
        canvas.toBlob((blob) => {
          // Save the image
          _this.saveImage(blob, smot.logicalId, type);
        }, 'image/webp', 0.6);
      };
    });

    reader.readAsDataURL(file);
  }

  public nextStep() {
    this.progressStepper.continue();
    this.changeDetector.detectChanges();
  }

  public displayRefill() {
    this.refillSmot = true;
    this.changeDetector.detectChanges();
  }
  saveCleanOperation(smot) {
    this.disableButtons = true;
    this.operationService.postOperation(this.createCleanOperation(smot)).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.router.navigate(['/operations']);
        this.toastr.showSucces('Maintenance Sucessfull', 'Succes');
        this.disableButtons = false;
      }),
      catchError(() => {
        this.disableButtons = false;
        return EMPTY;
      })
    ).subscribe();
  }

  submitCommentsForm() {
    this.handleSolvedComments();
    if (!this.checkIfAnyCommentsUnchecked()) {
      this.progressStepper.continue();
    } else {
      this.commentsSubmitted = true;
    }
  }

  displayAddCommentModal(smot: SmotDTO) {
    const dialog = this.dialog.open(InputModalComponent, {
      data: {
        key: 'translate.operations.smot-maintenance.addACommentToThisSMOTSpot',
        multiline: true
      }
    });
    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(comment => {
        const commentDTO = {
          type: CommentType.COMMENT,
          smotLogicalId: smot.logicalId,
          content: comment,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          creationDate: moment().utc()
        } as CreateRouteStepCommentDTO;

        return this.routeService.createComment(commentDTO).pipe(
          filter(x => x !== undefined)
        );
      })
    ).subscribe();
  }

  private postOperation(operation: OperationDTO) {
    return this.operationService.postOperation(operation)
      .pipe(
        filter(x => x !== undefined),
        tap(() => this.toastr.showSucces(this.translate.instant('translate.operations.shared.bagSuccesfullyReplaced'), this.translate.instant('translate.general.succes'))),
        catchError(error => {
          // TODO: test duplicate refill issues
          this.toastr.showErrorBasedOnStatus(error.status);
          return EMPTY;
        })
      );
  }

  private createCleanOperation(smot: SmotDTO) {
    return {
      smot: smot,
      operationType: 'CLEAN',
      info: ''
    } as OperationDTO;
  }

  private handleSolvedComments() {
    const solvedComments: RouteStepCommentDTO[] = this.comments.filter(c => c.solved)
      .map(c => ({
        ...c.comment,
    }) as RouteStepCommentDTO);
  }

  private mapSmotCommentsToCommentWithCheckStatus(comments: RouteStepCommentDTO[]) {
    if (!comments) { return; }
    comments = comments.filter(c => c.resolveDate === null || c?.type === CommentType.INFO);
    this.comments =  comments.map(c => ({
      solved: c.type === CommentType.INFO ? true : null,
      comment: c,
      type: c.type,
      reason: ''
    }) as CommentWithCheckStatus);
  }

  checkIfAnyCommentsUnchecked() {
    return this.comments.some(c => {
      return c.solved === null;
    });
  }

  handleUncheck(event, comment: CommentWithCheckStatus) {
    if (event.checked === true) {
      comment.solved = false;
    } else {
      comment.solved = null;
    }
  }

  private calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  private saveImage(image, logicalId, suffix) {
    return this.fileUpload.uploadOperationsPicture(image, logicalId, suffix).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.progressStepper.continue();
        this.disableButtons = false;
        this.changeDetector.detectChanges();
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        this.disableButtons = false;
        return EMPTY;
      })
    ).subscribe();
  }
}

interface CommentWithCheckStatus {
  comment: RouteStepCommentDTO;
  type: CommentType;
  solved: boolean;
  reason: string;
}
