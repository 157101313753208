import {RouteCommentsComponent} from '../views/operations-rework/routes/route-comments/route-comments.component';
import {UploadImageComponent} from '../views/operations-rework/routes/upload-image/upload-image.component';
import {RefillComponent} from '../views/operations-rework/routes/refill/refill.component';
import {FinishRouteComponent} from '../views/operations-rework/routes/finish-route/finish-route.component';
import {Type} from '@angular/core';
import {PlannedRouteDTO} from '../dtos/RouteDTOs/plannedRouteDTO';
import {ScanSmotComponent} from '../views/operations-rework/routes/scan-smot/scan-smot.component';

export const ROUTE_MAINTENANCE_FLOW: RouteFlow = {
  type: 'maintenance',
  steps: [
    {
      component: RouteCommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: ScanSmotComponent,
      data: {}
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'translate.operations.smot-maintenance.imageBeforeCleaning',
        uploadUrl: '/operation/',
        suffix: 'before',
      }
    },
    {
      component: RouteCommentsComponent,
      data: {
        requiredToCheckAllComments: true,
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'translate.operations.smot-maintenance.imageAfterCleaning',
        uploadUrl: '/operation/',
        suffix: 'after',
      }
    },
    {
      component: RefillComponent,
      data: {}
    },
    {
      component: FinishRouteComponent,
      data: {}
    }
  ]
};

export const ROUTE_INSTALL_FLOW: RouteFlow = {
  type: 'installation',
  steps: [
    {
      component: RouteCommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: ScanSmotComponent,
      data: {}
    },
    {
      component: RouteCommentsComponent,
      data: {
        requiredToCheckAllComments: true,
      }
    },
    {
      component: UploadImageComponent,
      data: {
        uploadUrl: '/operation/smot/',
        headerString: 'translate.operations.smot-image-upload.uploadSmotImage',
      }
    },
    {
      component: FinishRouteComponent,
      data: {}
    }
  ]
};

export const ROUTE_DEINSTALL_FLOW: RouteFlow = {
  type: 'deinstallation',
  steps: [
    {
      component: RouteCommentsComponent,
      data: {
        requiredToCheckAllComments: false,
      }
    },
    {
      component: ScanSmotComponent,
      data: {}
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'Upload een foto VOOR het kuisen',
        uploadUrl: '/operation/',
        suffix: 'before',
      }
    },
    {
      component: UploadImageComponent,
      data: {
        headerString: 'Upload een foto NA het kuisen',
        uploadUrl: '/operation/',
        suffix: 'after',
      }
    },
    {
      component: RouteCommentsComponent,
      data: {
        headerString: 'translate.routes.deinstallTheSmot',
        requiredToCheckAllComments: true,
      }
    },
    {
      component: FinishRouteComponent,
      data: {}
    }
  ]
};

export const ROUTE_CUSTOM_FLOW: RouteFlow = {
  type: 'custom',
  steps: [
    {
      component: RouteCommentsComponent,
      data: {
        headerString: 'custom',
        requiredToCheckAllComments: true,
      }
    },
    {
      component: FinishRouteComponent,
      data: {}
    }
  ]
};



export interface RouteFlow {
  type: 'maintenance' | 'installation' | 'deinstallation' | 'custom';
  steps: RouteFlowStep[];
}

interface RouteFlowStep {
  component: Type<any>;
  data: RouteFlowStepData;
}

export interface RouteFlowStepData {
  uploadUrl?: string;
  suffix?: string;
  requiredToCheckAllComments?: boolean;
  headerString?: string;
  videoUrl?: string;
  plannedRoute?: PlannedRouteDTO;
  stepId?: number;
}
