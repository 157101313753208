<div class="d-flex justify-content-center">
  <div *ngIf="smot$ | async as smot" class="sundo-container medium">
    <div class="stepper">
      <app-progress-step class="stepper" [amountOfSteps]="3" [currentStep]="1" #stepper></app-progress-step>
    </div>

    <ng-container #step1 *ngIf="stepper.currentStep === 1">
      <ng-container *ngIf="!showScanner else scanner">
        <div class="top-text">{{'translate.operations.smot-repair.whatPartRepair' | translate}}</div>
        <div class="part-select">
          <mat-form-field appearance="fill">
            <mat-label>{{'translate.operations.smot-repair.chooseAPart' | translate}}</mat-label>
            <mat-select [formControl]="partControl" required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let part of parts" [value]="part.value">
                {{part.label}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="partControl.hasError('required')">{{'translate.operations.smot-repair.pleaseSelectAPart' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-center">
          <app-success-button [class.disabled]="partControl.errors" (click)="handleNextOnPartSelect()">{{'translate.form.next' | translate}}
          </app-success-button>
        </div>
      </ng-container>

      <ng-template #scanner>
        <div class="top-text">
          <ng-container *ngIf="partControl.value === 'Backplate' else casing">
            <app-h3>
              {{'translate.operations.shared.scanQRonBackplate' | translate}}
            </app-h3>
          </ng-container>
          <ng-template #casing>
            <app-h3>
              {{'translate.operations.shared.scanQRInsideCasing' | translate}}
            </app-h3>
          </ng-template>
        </div>
        <div class="d-flex justify-content-center">
          <app-js-qr-scanner *ngIf="partControl.value === 'Backplate'" [badScan]="badScan"
                             (codeScanned)="scanCompleteHandler($event, 'backplate')"
          ></app-js-qr-scanner>
          <app-js-qr-scanner *ngIf="partControl.value === 'Casing'" [badScan]="badScan"
                          (codeScanned)="scanCompleteHandler($event, 'casing')"></app-js-qr-scanner>
        </div>
      </ng-template>
    </ng-container>

    <ng-container #step2 *ngIf="stepper.currentStep === 2">
      <div class="top-text">{{'translate.operations.smot-repair.doYouWishToUploadAnImage' | translate}}</div>
      <input
        type="file"
        style="display: none"
        accept="image/*"
        (change)="onFileSelect($event);"
        #fileInput>
      <div class="d-flex justify-content-center">
        <div class="buttons">
          <app-warning-button [loading]="loading" [fullWidth]="true" (click)="fileInput.click()">{{'translate.operations.shared.uploadImage' | translate}}</app-warning-button>
          <app-danger-button [fullWidth]="true" (click)="stepper.continue()">{{'translate.form.skip' | translate}}</app-danger-button>
        </div>
      </div>
    </ng-container>

    <ng-container #step3 *ngIf="stepper.currentStep === 3">
      <div class="top-text">{{'translate.operations.smot-repair.repairThePart' | translate}}</div>
      <div class="d-flex justify-content-center">
        <app-success-button [loading]="loading" (click)="repairCompleteHandler(smot)">{{'translate.form.submit' | translate}}</app-success-button>
      </div>
    </ng-container>
  </div>
</div>
