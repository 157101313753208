import {Component, Input, OnInit} from '@angular/core';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../../forms/validation-forms/validation-forms.service';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {RouteService} from '../../../../services/route.service';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EMPTY, forkJoin} from 'rxjs';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';
import {RouteStepCommentDTO} from '../../../../dtos/RouteDTOs/routeDTO';
import {CommentType} from '../../../../models/commentType';
import {ResolutionStatus} from '../../../../models/resolutionStatus';
import {TranslateService} from '@ngx-translate/core';
import {RouteMappings} from '../../../../models/dtoMappings';
import {OperationService} from '../../../../services/operation.service';
import {OperationDTO} from '../../../../dtos/OperationDTOs/operationDTO';
import {SmotService} from '../../../../services/smot.service';
import {UserService} from '../../../../services/user.service';
import {AuthenticationService} from '../../../../services/authentication.service';
import {UserDTO} from '../../../../dtos/UserDTOs/userDTO';
import moment from 'moment';

@Component({
  selector: 'app-finish-route',
  templateUrl: './finish-route.component.html',
  styleUrls: ['./finish-route.component.scss', '../../operations.scss']
})
export class FinishRouteComponent implements OnInit, BaseRouteStepComponent {
  @Input() data: RouteFlowStepData;
  commentFormControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.vf.formRules.nonEmpty)]);

  constructor(private vf: ValidationFormsService,
              private routeService: RouteService,
              private router: Router,
              private operationService: OperationService,
              private smotService: SmotService,
              private userService: UserService,
              private authService: AuthenticationService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  public finishStep() {
    const stepIndex = this.data.plannedRoute.plannedRouteSteps.findIndex(s => +this.data.stepId === +s.routeStep.routeStepId);
    this.data.plannedRoute.plannedRouteSteps[stepIndex].finishTime = moment().utc();
    this.routeService.updatePlannedRoute(this.data.plannedRoute).pipe(
      filter(x => x !== undefined),
      tap(() => this.routeService.clearStepStage()),
      tap(() => this.router.navigate(['operations/route-overview', this.data.plannedRoute.plannedRouteId])),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();

    const routeStep = this.data.plannedRoute.route.steps.find(s => +s.routeStepId === +this.data.stepId);
    if (routeStep.maintenance) {
      forkJoin([this.smotService.getSmot(routeStep.smot.logicalId), this.userService.getUser<UserDTO>(this.authService.getUsername())]).pipe(
        filter(x => x !== undefined),
        switchMap(([smot, user]) => this.operationService.postOperation(this.createCleanOperation(smot, user))
          .pipe(
            filter(x => x !== undefined),
          )),
        catchError(err => {
          this.toastr.showErrorBasedOnStatus(err.status);
          return EMPTY;
        })
      ).subscribe();
    }
  }

  private createCleanOperation(smot, user): OperationDTO {
    return {
      smot: smot,
      user: user,
      operationTime: moment().utc(),
      info: '',
      operationType: 'CLEAN'
    } as OperationDTO;
  }

  public postComment() {
    if (this.commentFormControl.invalid) {
      return;
    }

    const stepIndex = this.data?.plannedRoute?.route?.steps?.findIndex(s => +s.routeStepId === +this.data.stepId);
    if (stepIndex === -1 || stepIndex === undefined) {
      this.toastr.showErrorBasedOnStatus(500);
      return;
    }

    const smot = this.data.plannedRoute.route.steps[stepIndex].smot;
    const comment = this.createComment(smot);

    if (smot) {
      this.data.plannedRoute.route.steps[stepIndex].smot.routeStepComments.push(comment);
    } else {
      this.data.plannedRoute.route.steps[stepIndex].routeStepComments.push(comment);
    }

    this.routeService.updateRoute(this.data.plannedRoute.route, RouteMappings.DEFAULT, 'true', this.data.plannedRoute.route.steps[stepIndex].routeStepId).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.commentFormControl.reset();
        this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), 'Success');
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }

  private createComment(smot): RouteStepCommentDTO {
    return {
      type: CommentType.COMMENT,
      content: this.commentFormControl.value,
      resolutionStatus: ResolutionStatus.UNRESOLVED,
      creationDate: moment().utc(),
      routeStepId: smot ? null : +this.data.stepId,
      resolveDate: null,
      smotLogicalId: smot ? smot.logicalId : null,
      routeStepCommentId: null,
    };
  }
}
