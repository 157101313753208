import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RouteService} from '../../../../services/route.service';
import {filter, tap} from 'rxjs/operators';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-route-media',
  templateUrl: './route-media.component.html',
  styleUrls: ['./route-media.component.scss', '../../operations.scss']
})
export class RouteMediaComponent implements OnInit {
  @Input() data: RouteFlowStepData;
  imageDataURLs = [];
  currentImgDataURL = '';
  currentImgIndex = 0;
  displayImages: boolean = false;
  videoURL;
  loading: boolean = true;

  @Output() returnToStep = new EventEmitter();

  constructor(private routeService: RouteService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.sanitizeURL();
    this.getRouteStepImages();
  }

  public nextImage() {
    if (this.currentImgIndex === this.imageDataURLs.length - 1) {
      return;
    }

    this.currentImgIndex++;
    this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
  }

  public previousImage() {
    if (this.currentImgIndex === 0) {
      return;
    }
    this.currentImgIndex--;
    this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
  }

  public previousStep() {
    this.returnToStep.emit(true);
  }

  private sanitizeURL() {
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.data?.videoUrl);
  }

  private getRouteStepImages() {
    this.loading = true;
    return this.routeService.getRouteStepImages(this.data?.plannedRoute.route.routeId, this.data?.stepId).pipe(
      filter(x => x !== undefined),
      tap((images: any[]) => images.forEach(image => {
        this.imageDataURLs.push(image);
      })),
      tap(() => {
        this.loading = false;
        if (this.imageDataURLs.length) {
          this.displayImages = true;
          this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
        }
      })
    ).subscribe();
  }


}
