import {Component, Input, OnInit} from '@angular/core';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {ResolutionStatus} from '../../../../models/resolutionStatus';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {CommentType} from '../../../../models/commentType';
import {RouteService} from '../../../../services/route.service';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SkippedCommentDTO} from '../../../../dtos/skippedCommentDTO';
import {of} from 'rxjs/internal/observable/of';
import {MaintenanceDTO} from '../../../../dtos/MaintenanceDTO';
import {MaintenanceService} from '../../../../services/maintenance.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../../services/authentication.service';
import moment from 'moment';
import {RouteStepDTO} from '../../../../dtos/RouteDTOs/routeDTO';
import {MobileService} from '../../../../services/mobile.service';

@Component({
  selector: 'app-route-comments',
  templateUrl: './route-comments.component.html',
  styleUrls: ['./route-comments.component.scss', '../../operations.scss']
})
export class RouteCommentsComponent implements OnInit, BaseRouteStepComponent  {
  @Input() data: RouteFlowStepData;
  infoComments: any[] = [];
  maintenances: any[] = [];
  comments: any[] = [];
  maintenance$: Observable<MaintenanceDTO[]>;
  plannedRouteId: number;
  stepId: number;
  displayImages: boolean = false;
  step: RouteStepDTO;

  constructor(private routeService: RouteService,
              private toastr: ToastrNotificationService,
              private activatedRoute: ActivatedRoute,
              private maintenanceService: MaintenanceService,
              private translate: TranslateService,
              private mobileService: MobileService,
              private auth: AuthenticationService) {
    this.activatedRoute.params.pipe(
      tap(params => {
        this.plannedRouteId = params['plannedRouteId'];
        this.stepId = params['stepId'];
      })
    ).subscribe();
  }

  ngOnInit(): void {
    this.getCommentsFromStep();
  }

  public hasUnresolvedComments() {
    const unresolvedComment = !!this.comments.find(c => c.resolutionStatus === ResolutionStatus.UNRESOLVED && !c.skippedComment);
    const unresolvedMaintenance = this.maintenances.find(m => !m.checked && !m.skipped);
    return unresolvedComment || unresolvedMaintenance;
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  public nextStep() {
    this.postMaintenance();

    this.postSkippedComments().pipe(
      tap(() => {
        const stepIndex = this.data?.plannedRoute?.route?.steps.findIndex(s => +s.routeStepId === +this.data.stepId);
        if (stepIndex === -1 || stepIndex === undefined) {
          this.toastr.showErrorBasedOnStatus(500);
          return;
        }

        if (this.data.plannedRoute.route.steps[stepIndex].smot) {
          this.data.plannedRoute.route.steps[stepIndex].smot.routeStepComments = this.comments.concat(this.infoComments);
        } else {
          this.data.plannedRoute.route.steps[stepIndex].routeStepComments = this.comments.concat(this.infoComments);
        }

        this.routeService.updateRoute(this.data.plannedRoute.route).pipe(
          filter(x => x !== undefined),
          tap(() => this.routeService.emitOnStepCompleted())
        ).subscribe();
      })
    ).subscribe();
  }

  public navigateWithWaze(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;
    if (this.mobileService.detectMobileDeviceByUserAgent()) {
      window.open(`waze://?ll=${lat},${lng}&navigate=yes`, '_blank');
    } else {
      window.open(`https://waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location`, '_blank');
    }
  }

  public navigateWithGoogleOrAppleMaps(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;

    window.open(`https://maps.apple.com/?q=${lat},${lng}`, '_blank');
  }

  private postMaintenance() {
    const maintenancesToUpdate = this.maintenances.filter(m => m.checked && !m.skipped);

    maintenancesToUpdate.forEach(m => {
      this.maintenanceService.updateMaintenance(m.maintenanceId, m).subscribe();
    });
  }

  private postSkippedComments() {
    let comments = this.comments.concat(this.infoComments).filter(x => x.skippedComment);
    comments = comments.map(comment => ({
      reason: comment.reason,
      routeStepCommentId: comment.routeStepCommentId
    }) as SkippedCommentDTO);

    if (comments.length <= 0) {
      return of(null);
    }

    return this.routeService.createSkippedComments(this.plannedRouteId, this.stepId, comments).pipe(
      filter(x => x !== undefined),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    );
  }

  public updateComment(comment, event: MatCheckboxChange) {
    comment.skippedComment = false;
    comment.resolveDate = event.checked ? moment().utc() : null;
    comment.resolutionStatus = event.checked ? ResolutionStatus.RESOLVED : ResolutionStatus.UNRESOLVED;
  }

  public updateMaintenance(maintenance, event: MatCheckboxChange) {
    maintenance.skipped = false;
    maintenance.repairUsername = this.auth.getUsername();
    maintenance.resolveDate = event.checked ? moment().utc() : null;
    maintenance.repairStatus = event.checked ? 1 : 0;
  }

  public skipComment(comment) {
    comment.skippedComment = true;
    comment.resolveDate = null;
    comment.resolutionStatus = ResolutionStatus.UNRESOLVED;
  }

  public skipMaintenance(maintenance) {
    maintenance.skipped = true;
    maintenance.checked = false;
  }

  private getCommentsFromStep() {
    const step = this.data.plannedRoute.route.steps.find(s => +s.routeStepId === +this.data.stepId);
    this.step = step;

    if (step.smot) {
      this.comments = step.smot.routeStepComments.filter(c => c.type === CommentType.COMMENT);
      this.infoComments = step.smot.routeStepComments.filter(c => c.type === CommentType.INFO);
      this.maintenance$ = this.maintenanceService.getMaintenanceBySmotLogicalId(step.smot.logicalId).pipe(
        filter(x => x !== undefined),
        map(maintenances => maintenances.map(maintenance => ({
          ...maintenance,
          checked: false,
          description: `${maintenance.repairPart} ${this.translate.instant('translate.routes.repair')}`
        }) as MaintenanceDTO)),
        tap(maintenances => {
          this.maintenances = maintenances;
        })
      );
    } else {
      this.comments = step.routeStepComments.filter(c => c.type === CommentType.COMMENT);
      this.infoComments = step.routeStepComments.filter(c => c.type === CommentType.INFO);
      this.maintenance$ = of([]);
    }

    this.comments = this.comments.filter(c => c.resolutionStatus !== ResolutionStatus.CONFIRMED).map(comment => ({
      ...comment,
      skippedComment: false
    }));

    this.infoComments = this.infoComments.map(comment => ({
      ...comment,
      resolutionStatus: ResolutionStatus.UNRESOLVED,
      skippedComment: false
    }));
  }
}
