<div class="sundo-container medium h-100 m-auto position-relative">
  <div class="back-button" (click)="previousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <div class="text-center">
    <app-h2>{{data?.headerString | translate}}</app-h2>
    <ng-container *ngIf="loading">
      <div class="center">
        <mat-spinner [color]="'accent'"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && !imageDataURLs.length">
      <app-h3>Geen afbeeldingen gevonden</app-h3>
    </ng-container>
    <iframe width="330" height="185"  *ngIf="!displayImages && data?.videoUrl"  [src]="videoURL"
            title="Sundo instructievideo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
  </div>
  <div class="image-carousel" *ngIf="displayImages && imageDataURLs.length">
    <img [src]="currentImgDataURL" alt="">
    <span (click)="previousImage()" class="carousel-arrow previous">
        <ng-container  *ngIf="this.currentImgIndex > 0">
          <a><</a>
        </ng-container>
      </span>
    <span (click)="nextImage()" class="carousel-arrow next">
         <ng-container  *ngIf="this.currentImgIndex < this.imageDataURLs.length - 1">
          <a>></a>
        </ng-container>
      </span>
  </div>
  <div class="buttons">
    <app-primary-button [fullWidth]="true" *ngIf="imageDataURLs.length && !displayImages" (click)="displayImages = true" >{{'Afbeeldingen' | translate}}</app-primary-button>
    <app-primary-button [fullWidth]="true" *ngIf="data?.videoUrl && displayImages" (click)="displayImages = false" >{{'Instructie video' | translate}}</app-primary-button>
    <app-warning-button [fullWidth]="true" (click)="previousStep()" >{{'translate.form.back' | translate}}</app-warning-button>
  </div>
</div>
