<div class="sundo-container medium h-100 m-auto position-relative">
  <div class="back-button" (click)="previousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <app-h2 class="text-center">{{'translate.routes.stepComplete' | translate}}</app-h2>
  <div class="d-flex justify-content-center add-comment">
    <div>
      <app-input-field
        [placeholder]="'translate.routes.comment' | translate"
        [amountOfLines]="5"
        [hideLabel]="true"
        [multiLine]="true"
        [label]="'translate.routes.comment' | translate"
        [control]="commentFormControl"
      ></app-input-field>
      <app-warning-button [fullWidth]="true" (click)="postComment()"> {{ 'translate.routes.addComment' | translate }}</app-warning-button>
    </div>
  </div>
  <div class="buttons">
    <app-success-button [fullWidth]="true" (click)="finishStep()">{{'translate.form.submit' | translate}}</app-success-button>
  </div>
</div>
