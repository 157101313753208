import {Component, OnInit} from '@angular/core';
import {filter, switchMap, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {FileUploadService} from '../../../services/file-upload.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {OperationService} from '../../../services/operation.service';
import {SmotService} from '../../../services/smot.service';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {EditImgModalComponent} from '../../plugins/edit-img-modal/edit-img-modal.component';
import {ImageCreateService} from '../../../services/image-create.service';

@Component({
  selector: 'app-smot-image-upload',
  templateUrl: './smot-image-upload.component.html',
  styleUrls: ['./smot-image-upload.component.scss', '../operations.scss']
})
export class SmotImageUploadComponent implements OnInit {

  smot$: Observable<SmotDTO>;
  smotId;
  smotSpot: SmotDTO;

  constructor(private dialog: MatDialog,
              private fileUploadService: FileUploadService,
              private toastr: ToastrNotificationService,
              private router: Router,
              private operationService: OperationService,
              private activatedRoute: ActivatedRoute,
              private imageCreateService: ImageCreateService,
              private smotService: SmotService) {
    this.activatedRoute.paramMap.subscribe(params => this.smotId = params.get('id'));
  }

  ngOnInit(): void {
    this.smot$ = this.operationService.operationSmot$.pipe(
      switchMap(smot => {
        if (smot !== undefined) { return of(smot); }
        return this.smotService.getSmot(+this.smotId);
      }),
      tap(smot => this.smotSpot = smot)
    );
  }

  onFileSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
        this.openCropperModal(reader.result);
    });

    reader.readAsDataURL(file);
  }

  private openCropperModal(imgToCrop) {
    const dialog = this.dialog.open(EditImgModalComponent, {
      data: {
        imageSource: imgToCrop
      }
    });

    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      tap((img) => this.saveSmotImg(img)),
    ).subscribe();
  }

  private saveSmotImg(result) {
    this.imageCreateService.urltoFile(result, 'smot', 'image/png').then((file) => {
      this.fileUploadService.uploadSmotProfilePicture(file, this.smotSpot)
        .pipe(
          filter(x => x !== undefined),
          tap(() => this.router.navigate(['/operations'])),
          tap(() => this.toastr.showSucces('Image Succesfully Uploaded', 'Success'))
        )
        .subscribe();
    });
  }
}
