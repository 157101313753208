import {Injectable} from '@angular/core';
import {ScannedQR} from '../models/ScannedQR';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  backplateRegexFormat: RegExp;
  casingRegexFormat: RegExp;
  sunscreenRegexFormat: RegExp;
  seasonRegexFormat: RegExp;

  constructor() {
    this.backplateRegexFormat = new RegExp('^.*bck=[0-9]{5}$');
    this.casingRegexFormat = new RegExp('^.*cas=[0-9]{5}$');
    this.sunscreenRegexFormat = new RegExp('^.*sun=[0-9]{6}$');
    this.seasonRegexFormat = new RegExp('^.*sea=[0-9]{5}$');
  }

  verifyScan(scannedText: string): ScannedQR {
    if (!scannedText) {
      return null;
    }
    // This replaces the '-' in the manual code to a '=' to fit the RegExp
    const tempText = scannedText.slice(scannedText.length - 9).replace('-', '=');
    scannedText = scannedText.slice(0, scannedText.length - 9);
    scannedText += tempText;

    // To lower case in case the url contains uppercases in for the RegExp (e.g CAS, Bck, Sun)
    scannedText = scannedText.toLocaleLowerCase();
    if (this.isCasingScan(scannedText)) {
      const cas = scannedText.slice(scannedText.length - 5);
      return {
        type: 'casing',
        casingId: cas,
        backplateId: null
      };
    }
    if (this.isBackplateScan(scannedText)) {
      const bck = scannedText.slice(scannedText.length - 5);
      return {
        type: 'backplate',
        casingId: null,
        backplateId: bck
      };
    }
    if (this.isSunscreenScan(scannedText)) {
      const sun = scannedText.slice(scannedText.length - 6);
      return {
        type: 'sunscreen',
        casingId: null,
        backplateId: null,
        sunscreenId: sun
      };
    }
    if (this.isSeasonScan(scannedText)) {
      const season = scannedText.slice(scannedText.length - 5);
      return {
        type: 'season',
        casingId: null,
        backplateId: null,
        seasonId: season
      };
    }
    return null;
  }

  isSunscreenScan(scannedText) {
    return this.sunscreenRegexFormat.test(scannedText);
  }

  isBackplateScan(scannedText) {
    return this.backplateRegexFormat.test(scannedText);
  }

  isCasingScan(scannedText) {
    return this.casingRegexFormat.test(scannedText);
  }

  isSeasonScan(scannedText) {
    return this.seasonRegexFormat.test(scannedText);
  }
}
