import {Component, Input, OnInit} from '@angular/core';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {RouteService} from '../../../../services/route.service';
import {SmotDTO} from '../../../../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../../../../dtos/OperationDTOs/operationDTO';
import {ScannedQR} from '../../../../models/ScannedQR';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {OperationService} from '../../../../services/operation.service';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {SmotService} from '../../../../services/smot.service';
import {ScannerService} from '../../../../services/scanner.service';
import {EMPTY} from 'rxjs';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';

@Component({
  selector: 'app-refill',
  templateUrl: './refill.component.html',
  styleUrls: ['./refill.component.scss', '../../operations.scss']
})
export class RefillComponent implements OnInit, BaseRouteStepComponent  {
  @Input() data: RouteFlowStepData;

  loading: boolean = false;
  badScan: boolean = false;
  step: 'QUESTION' | 'SCANNER' | 'FINISH' = 'QUESTION';
  constructor(private routeService: RouteService,
              private operationService: OperationService,
              private smotService: SmotService,
              private scanService: ScannerService,
              private toastr: ToastrNotificationService) {
  }


  ngOnInit(): void {
  }

  public nextStep() {
    this.routeService.emitOnStepCompleted();
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  private createRefillOperation(sunscreenId: number, smot: SmotDTO) {
    return {
      smot: smot,
      operationType: 'REFILL',
      info: JSON.stringify({
        newPackageId: sunscreenId,
        previousPackageId: smot.packaging?.packageId
      })
    } as OperationDTO;
  }

  handleSuccessfulCodeScan(event) {
    if (this.loading) {
      return;
    }

    this.badScan = false;
    this.loading = true;

    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) {
      this.badScan = true;
      this.loading = false;
      return;
    }

    const smotLogicalId = this.data.plannedRoute.route.steps.find(s => +s.routeStepId === +this.data.stepId)?.smot?.logicalId;
    this.smotService.getSmot(smotLogicalId).pipe(
      filter(x => x !== undefined),
      switchMap(smot => this.operationService.postOperation(this.createRefillOperation(+scannedQR.sunscreenId, smot))
        .pipe(
          filter(x => x !== undefined),
          tap(() => {
            this.nextStep();
            this.loading = false;
          })
        )),
      catchError(err => {
        this.loading = false;
        return EMPTY;
      })
    ).subscribe();
  }
}
