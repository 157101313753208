import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SmotScannerComponent} from './smot-scanner/smot-scanner.component';
import {SmotOperationComponent} from './smot-operation/smot-operation.component';
import {SmotMaintenanceComponent} from './smot-maintenance/smot-maintenance.component';
import {SmotRepairComponent} from './smot-repair/smot-repair.component';
import {SmotImageUploadComponent} from './smot-image-upload/smot-image-upload.component';
import {CreateDeviceComponent} from './create-device/create-device.component';
import {CreateSmotComponent} from './create-smot/create-smot.component';
import {InstructionVideosComponent} from '../instruction-videos/instruction-videos.component';
import {StartRouteComponent} from './routes/start-route/start-route.component';
import {RouteOverviewComponent} from './routes/route-overview/route-overview.component';
import {ActiveRouteStepComponent} from './routes/active-route-step.component';

const routes: Routes = [
  {
    path: '',
    component: SmotScannerComponent,
    data: {
      title: 'Operations'
    }
  },
  {
    path: 'smot/:id',
    component: SmotOperationComponent,
    data: {
      title: 'SMOTSpot Operations'
    }
  },
  {
    path: 'smot/:id/maintenance',
    component: SmotMaintenanceComponent,
    data: {
      title: 'SMOTSpot Maintenance'
    }
  },
  {
    path: 'smot/:id/repair',
    component: SmotRepairComponent,
    data: {
      title: 'SMOTSpot Repair'
    }
  },
  {
    path: 'smot/:id/smot-image',
    component: SmotImageUploadComponent,
    data: {
      title: 'SMOTSpot Image Upload'
    }
  },
  {
    path: 'device/create-device',
    component: CreateDeviceComponent,
    data: {
      title: 'Create Device'
    }
  },
  {
    path: 'device/:id/create-smot',
    component: CreateSmotComponent,
    data: {
      title: 'Create SMOTSpot'
    }
  },
  {
    path: 'start-route',
    component: StartRouteComponent,
    data: {
      title: 'Start Route'
    }
  },
  {
    path: 'route-overview/:id',
    component: RouteOverviewComponent,
    data: {
      title: 'Route Overview'
    }
  },
  {
    path: 'route-overview/:plannedRouteId/step/:stepId',
    component: ActiveRouteStepComponent,
    data: {
      title: 'Perform Route'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule { }
