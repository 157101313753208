<div class="position-relative" data-cy="container">
  <div class="input" >
    <p class="label" *ngIf="!hideLabel">{{label | translate}}<span *ngIf="isRequired">*</span></p>
    <input *ngIf="search else select"
           (click)="toggleDropdown()"
           (keyup)="handleKeyPress($event)"
           [formControl]="searchControl"
           [class.disable]="disabled"
           [ngClass]="{'invalid' : control.errors && control.touched}"
           [placeholder]="label | translate"
           class="form-field"
           type="text"
           data-cy="dropdown-input"
    >
    <ng-template #select>
      <div class="form-field"
           (click)="toggleDropdown()"
           [class.disabled]="disabled"
           [ngClass]="{'invalid' : control.errors && control.touched}">
        {{this.searchControl.value ? this.searchControl.value : label | translate}}
        <svg [style.rotate]="hidden ? '0deg' : '180deg'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M1 3h22L12 22"/></svg>
      </div>
    </ng-template>
    <div *ngIf="control.errors && control.touched">
      <span class="error-message-minimal" *ngIf="control.errors['required']">{{'translate.form.isRequired' | translate:{'item': label} }}</span>
    </div>
  </div>
  <div class="drop-down" data-cy="drop-down" [hidden]="hidden" *ngIf="options$ | async else loading">
    <div class="option" *ngFor="let option of filteredOptions" (click)="handleOptionSelect(option)">
      <ng-container *ngIf="multiSelect else label">
        <mat-checkbox [(ngModel)]="option.selected" (ngModelChange)="handleMultiSelectChange(filteredOptions)">{{option.label | translate}}</mat-checkbox>
      </ng-container>
      <ng-template #label>
        {{option.label | translate}}
      </ng-template>
    </div>
  </div>
  <ng-template #loading>
    <div class="drop-down" [hidden]="hidden">
      <div class="option" >Loading...</div>
    </div>
  </ng-template>
</div>

