<div class="d-flex justify-content-center">
  <div *ngIf="device$ | async as device" class="sundo-container medium">
    <div class="stepper">
      <app-progress-step [amountOfSteps]="5" [currentStep]="1" #stepper></app-progress-step>
    </div>

    <!--  STEP 1  -->
    <ng-container #step1 *ngIf="stepper.currentStep === 1">

      <ng-container *ngIf="!showSmotConfig && !showSmotForm && !showSeasonScanner">
        <ng-container *ngIf="!showScanner else scanner">
          <div class="d-flex justify-content-around top-text">
            <div>
              {{ 'translate.operations.shared.backplateId' | translate}}:
              <div>{{device.backplate.backplateId}}</div>
            </div>
            <div>
              {{ 'translate.operations.shared.casingId' | translate}}:
              <div>{{device.casingId}}</div>
            </div>
          </div>
          <div class="top-text">
            {{ 'translate.operations.create-smot.deviceNotLinked' | translate}}
          </div>
          <div class="d-flex justify-content-center">
            <div class="buttons">
              <app-warning-button [fullWidth]="true" (click)="changeBackplate(device)">{{ 'translate.operations.create-device.otherBackplate' | translate}}</app-warning-button>
              <app-warning-button [fullWidth]="true" (click)="changeCasing(device)">{{ 'translate.operations.create-device.otherCasing' | translate}}</app-warning-button>
              <app-warning-button [fullWidth]="true" (click)="disassembleDevice(device)">{{'translate.operations.create-device.disassembleDevice' |translate}}</app-warning-button>
              <app-success-button [fullWidth]="true" (click)="showSmotConfig = true">{{ 'translate.form.next' | translate}}</app-success-button>
            </div>
          </div>
        </ng-container>
        <ng-template #scanner>
          <ng-container *ngIf="device.casingId === 0 else backplate">
            <div class="top-text">{{ 'translate.operations.shared.scanQRInsideCasing' | translate}}</div>
          </ng-container>
          <ng-template #backplate>
            <app-h3 class="text-center">{{ 'translate.operations.shared.scanQRonBackplate' | translate}}</app-h3>
          </ng-template>
          <div class="d-flex justify-content-center">
            <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanCompleteHandler($event, device)"></app-js-qr-scanner>
          </div>
        </ng-template>
      </ng-container>
      <!--    CONFIG  -->
      <ng-container *ngIf="showSmotConfig">
        <div class="d-flex justify-content-center">
          <div class="buttons">
            <app-warning-button [fullWidth]="true" (click)="goToClearForm()">{{ 'translate.operations.create-smot.newConfiguration' | translate}}</app-warning-button>
            <app-warning-button [fullWidth]="true" (click)="goToConfigForm()" [class.disabled]="!config">{{ 'translate.operations.create-smot.useConfiguration' | translate}}
            </app-warning-button>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <div class="scrollable config-list" *ngIf="configs$ | async as configurations">
            <mat-accordion class="configurations-panel" multi>
              <mat-expansion-panel hideToggle *ngFor="let configuration of configurations"
                                   [ngStyle]="configuration.logicalId===config?.logicalId?{'border': '#ffd740 2px solid'} : {'border': 'none'}">
                <mat-expansion-panel-header>
                  <mat-panel-title style="text-overflow:ellipsis; width: 50%">
                    {{truncateString(configuration.locationDescription, 15)}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{configuration.client.clientName}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="d-flex justify-content-between">
                  <p>{{configuration.wakeUpTime}}:00 - {{configuration.sleepTime}}:00</p>
                  <ng-container *ngIf="configuration.isEvent === 1 else noEvent">
                    <p>{{'translate.create.event' | translate}}: {{'translate.general.yes' | translate}}</p>
                  </ng-container>
                  <ng-template #noEvent>
                    <p>{{'translate.create.event' | translate}}: {{'translate.general.no' | translate}}</p>
                  </ng-template>
                </div>
                <div class="d-flex justify-content-between">
                  <p>{{configuration.language}}</p>
                  <app-primary-button style="min-width: 0;" (click)="config = configuration"> {{'translate.form.select' | translate}} </app-primary-button>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </ng-container>
      <!--    FORM    -->
      <ng-container *ngIf="showSmotForm">
        <div class="d-flex justify-content-center">
          <form style="width: 300px" [formGroup]="smotFormGroup" novalidate (ngSubmit)="onSubmit(device)">
            <div class="form-group">
              <app-input-field [control]="smotName" [label]="'translate.operations.create-smot.smotName' | translate"></app-input-field>
            </div>
            <div class="form-group">
              <ng-container *ngIf="smotFormGroup.contains('client')">
                <app-drop-down-field
                  [search]="true"
                  [label]="'translate.create.client' | translate"
                  [options$]="clients$"
                  [control]="smotClient"
                  [initialSelectedValue]="smotClient.value"
                ></app-drop-down-field>
              </ng-container>
            </div>

            <div class="form-group">
              <app-drop-down-field
                  [label]="'translate.create.language' | translate"
                  [options$]="languages$"
                  [control]="smotLanguage">
              </app-drop-down-field>
            </div>
            <div class="d-flex justify-content-between">
              <div class="form-group">
                <label class="col-form-label" for="wakeUpTime">{{ 'translate.smot.wakeupTime' | translate}}</label>
                <div style="display: flex">
                  <input id="wakeUpTime" [formControl]="smotStartup" class="form-control"
                         [ngxTimepicker]="wakeUpTimepicker"
                         [format]="24"
                  />
                  <ngx-material-timepicker-toggle [for]="wakeUpTimepicker"></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker matSuffix #wakeUpTimepicker [hoursOnly]="true"
                                           [enableKeyboardInput]="true"></ngx-material-timepicker>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label" for="sleepTime">{{'translate.smot.sleepTime' | translate}}</label>
                <div style="display: flex">
                  <input id="sleepTime" [formControl]="smotSleep" class="form-control"
                         [ngxTimepicker]="sleepTimepicker"
                         [format]="24"
                  />
                  <ngx-material-timepicker-toggle [for]="sleepTimepicker"></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker matSuffix #sleepTimepicker [hoursOnly]="true"
                                           [enableKeyboardInput]="true"></ngx-material-timepicker>
                </div>
              </div>
            </div>
            <div class="form-group">
              <mat-slide-toggle [formControl]="smotEvent">{{'translate.create.event' | translate}}</mat-slide-toggle>
            </div>
            <div class="form-group">
              <mat-slide-toggle [formControl]="isOwnMaintenanceControl">{{'translate.create.maintenanceContract' | translate}}</mat-slide-toggle>
            </div>
            <div class="d-flex justify-content-around flex-wrap">
              <app-success-button [type]="'submit'" [disabled]="disableButtons" [class.disabled]="disableButtons">{{'translate.form.next' | translate}}</app-success-button>
              <app-danger-button [type]="'submit'" (click)="smotFormGroup.reset()">{{'translate.form.reset' | translate}}</app-danger-button>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-container>

    <!--  STEP 2  -->
    <ng-container #step2 *ngIf="stepper.currentStep === 2">
      <ng-container #seasonScanner *ngIf="showSeasonScanner">
        <app-h3 class="text-center">{{'translate.operations.create-smot.scanQRCodeRightOfDevice' | translate}}</app-h3>
        <div class="d-flex justify-content-center">
          <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanSeasonIdCompleteHandler($event)"></app-js-qr-scanner>
        </div>
      </ng-container>
    </ng-container>

    <!--  STEP 3  -->
    <ng-container #step3 *ngIf="stepper.currentStep === 3">
      <div class="d-flex justify-content-center">
        <div class="buttons">
          <app-success-button [size]="'big'" (click)="activateSmot()">{{'translate.operations.shared.activateSmot' | translate}}</app-success-button>
        </div>
      </div>
    </ng-container>

    <!--  STEP 4  -->
    <ng-container #step4 *ngIf="stepper.currentStep === 4">
      <ng-container *ngIf="!showSunscreenScan else sunscreenScanner">
        <div class="top-text">
          {{'translate.operations.create-smot.doYouWishToRefillSunscreen' | translate}}
        </div>
        <div class="d-flex justify-content-center">
          <div class="buttons d-flex justify-content-around flex-wrap">
            <app-success-button [fullWidth]="true" [type]="'submit'" (click)="showSunscreenScan = true">{{'translate.general.yes' | translate}}</app-success-button>
            <app-danger-button [fullWidth]="true" [type]="'submit'" (click)="stepper.continue()">{{'translate.general.no' | translate}}</app-danger-button>
          </div>
        </div>
      </ng-container>
      <ng-template #sunscreenScanner>
        <app-h3 class="text-center">{{'translate.operations.shared.scanQRCodeOnBag' | translate}}</app-h3>
        <div class="d-flex justify-content-center">
          <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanSunscreenCompleteHandler($event)"></app-js-qr-scanner>
        </div>
      </ng-template>
    </ng-container>

    <!--  STEP 5  -->
    <ng-container #step5 *ngIf="stepper.currentStep === 5">
      <div class="top-text">{{'translate.operations.create-smot.doYouWishToUploadAnImage' | translate}}</div>
      <div class="d-flex justify-content-center">
        <div class="buttons">
          <input
            type="file"
            style="display: none"
            accept="image/*"
            (change)="onFileSelect($event)"
            #fileInput>
          <app-success-button [fullWidth]="true" (click)="fileInput.click()">{{'translate.operations.shared.uploadImage' | translate}}</app-success-button>
          <app-warning-button [fullWidth]="true" [routerLink]="['/operations']">{{'translate.form.skip' | translate}}</app-warning-button>
        </div>
      </div>
    </ng-container>

  </div>
</div>
