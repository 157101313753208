import {Component, OnInit, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {OperationService} from '../../../services/operation.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceService} from '../../../services/device.service';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {of} from 'rxjs/internal/observable/of';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss', '../operations.scss']
})
export class CreateDeviceComponent implements OnInit {

  device$: Observable<DeviceDTO>;
  device: DeviceDTO;

  showScanner = false;
  badScan = false;

  @ViewChild('stepper') progressStepper;

  constructor(private operationService: OperationService,
              private scanService: ScannerService,
              private deviceService: DeviceService,
              private dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private router: Router) { }

  ngOnInit(): void {
    this.device$ = this.operationService.operationDevice$.pipe(
      tap(value => (value === undefined) ? this.router.navigate(['/operations']) : value));
  }

  scanCompleteHandler(event, device: DeviceDTO) {
    this.badScan = false;
    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }

    if (scannedQR.type === 'backplate' && device.backplate.backplateId === 0) {
      this.checkBackplateAvailability(scannedQR.backplateId).pipe(
        tap(result => {
          if (result) {
            device.backplate.backplateId = +scannedQR.backplateId;
            this.progressStepper.continue();
          }
        })
      ).subscribe();
    } else if (scannedQR.type === 'casing' && device.casingId === 0) {
      this.checkCasingAvailability(scannedQR.casingId).pipe(
        tap(result => {
          if (result) {
            device.casingId = +scannedQR.casingId;
            this.progressStepper.continue();
          }
        })
      ).subscribe();
    } else {
      this.badScan = true;
    }
  }

  changeCasing(device: DeviceDTO) {
    device.casingId = 0;
    this.progressStepper.return();
  }

  changeBackplate(device: DeviceDTO) {
    device.backplate.backplateId = 0;
    this.progressStepper.return();
  }

  createDevice(device: DeviceDTO) {
    this.deviceService.createDevice(device).pipe(
      filter(x => x !== undefined),
      tap(newDevice => {
        if (newDevice) {
          this.device = newDevice;
          this.progressStepper.continue();
        }
      })
    ).subscribe();
  }

  goToCreateSmot() {
    this.operationService.operationDeviceChanged(this.device);
    this.router.navigate([`/operations/device/${this.device.deviceId}/create-smot`]);
  }

  goToOperations() {
    this.operationService.removeOperationDevice();
    this.router.navigate(['/operations']);
  }

  private checkCasingAvailability(id) {
    return this.deviceService.getDeviceByCasing(id).pipe(
      filter(x => x !== undefined),
      switchMap(result => {
        if (result === null) {
          return of(true);
        } else {
          return this.showConfirmActionModal('translate.operations.create-device.casingIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice');
        }
      }));
  }

  private checkBackplateAvailability(id) {
    return this.deviceService.getDeviceByBackplate(id).pipe(
      filter(x => x !== undefined),
      switchMap(result => {
        if (result === null) {
          return of(true);
        } else {
          return this.showConfirmActionModal('translate.operations.create-device.backplateIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice');
        }
      }));
  }
  private showConfirmActionModal(message: String) {
    const dialog = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        key: message
      }
    });

    return dialog.afterClosed().pipe(
      switchMap((result) => of(!!result))
    );
  }
}
